<template>
<div class="connecteurImg">
  <img class="connecteurImg" v-bind:src="connectorImgSrc"
     v-on:click="handleClick" />
</div>
</template>

<script>
import connectorImgSrc from '@/assets/on-off/Connecteur.png'

export default {
  name: 'connector',
  data () {
    return {
      connectorImgSrc: connectorImgSrc
    }
  },
  props: {
    modId: {
      type: Number,
      required: true
    },
    isConnected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick: function () {
      this.$emit(this.isConnected ? 'disconnect' : 'connect', this.modId)
    }
  }
}
</script>

<style scoped>
.connecteurImg{
  position: relative;
  display: flex;
}
img {
  position: absolute;
  display: inline-block;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
}

</style>
