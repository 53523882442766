<template>
  <div class="switch">
    <img v-bind:src="isActive ? onImgSrc : offImgSrc"
     v-on:click="handleClick">
  </div>
</template>

<script>
import onImgSrc from '@/assets/on-off/On.png'
import offImgSrc from '@/assets/on-off/Off.png'

import clicks from '@/lib/clicks.js'

export default {
  name: 'push-button',
  props: {
    trackId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      clicks: clicks.sounds,
      isActive: false,
      onImgSrc: onImgSrc,
      offImgSrc: offImgSrc
    }
  },
  methods: {
    handleClick: function (event) {
      this.isActive = !this.isActive
      this.$emit('push', this.isActive, this.trackId)

      if (this.isActive) {
        this.clicks.CLICK_2_ON.click()
      } else {
        this.clicks.CLICK_2_OFF.click()
      }
    }
  }
}
</script>

<style scoped>

.switch{
  position: relative;
  display: flex;
}

img {
  position: absolute;
  display: inline-block;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

</style>
