<template>
  <div class="pswitch">
    <img v-bind:src="isActive ? onImgSrc : offImgSrc"
     v-on:click="handleClick">
  </div>
</template>

<script>
import onImgSrc from '@/assets/on-off/PSwitch_On.png'
import offImgSrc from '@/assets/on-off/PSwitch_Off.png'

import clicks from '@/lib/clicks.js'

export default {
  name: 'switch-button',
  props: {
    trackId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      isActive: false,
      onImgSrc: onImgSrc,
      offImgSrc: offImgSrc
    }
  },
  methods: {
    handleClick: function (event) {
      this.isActive = !this.isActive
      this.$emit('switch', this.isActive, this.trackId)

      if (this.isActive) {
        clicks.sounds.CLICK_1_ON.click()
      } else {
        clicks.sounds.CLICK_1_OFF.click()
      }
    }
  }
}
</script>

<style scoped>
.pswitch {
  position: relative;
  display: flex;
}
img {
  position: absolute;
  display: inline-block;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
</style>
