<template>
<div class="cable">
  <img v-bind:src="cableImgSrc"
      v-bind:style="{ display: isConnected ? 'block' : 'none' }" />
</div>
</template>

<script>
import yellowImgSrc from '@/assets/on-off/CableJaune.png'
import redImgSrc from '@/assets/on-off/CableRouge.png'
import greenImgSrc from '@/assets/on-off/CableVert.png'

export default {
  name: 'cable',
  props: {
    isConnected: {
      type: Boolean,
      required: false
    },
    cableColor: {
      type: String,
      required: true,
      validator (value) {
        return ['yellow', 'red', 'green'].includes(value)
      }
    }
  },
  data () {
    return {
      cableImgSources: {
        yellow: yellowImgSrc,
        red: redImgSrc,
        green: greenImgSrc
      }
    }
  },
  computed: {
    cableImgSrc: function () {
      return this.cableImgSources[this.cableColor]
    }
  }
}
</script>

<style scoped>
img {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  display: inline-block;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.cable{
  position: relative;
  display: flex;
}

</style>
