<template>
<div class="on-off">
  <header>
    <h1>ON/OFF (1,0)</h1>
    <p>Une installation sonore interactive du duo Ménard/St-Amand.</p>
  </header>
  <div class="videoSection">
    <video class="videoParent" src= 'https://telharmonium-web.s3.us-east-2.amazonaws.com/OnOffComplet.mp4' type="video/mp4" controls poster="@/assets/onoffThumbnail.png">
    </video>
  </div>
  <h1>ON/OFF WEB</h1>
    <div class="instruction">
      <p>
        Voici la version <em>application web</em> de notre installation sonore On/Off (1,0). Vous pouvez interagir avec elle en utilisant les différents interrupteurs. Les connecteurs servent à changer la banque de sons. Ensuite, vous pouvez composer une courte pièce grâce au séquenceur en bas de la page.
      </p>
    </div>
  <main>
    <ecran v-bind:connectedMod="connectedMod"
           v-on:connect="handleConnect"
           v-on:disconnect="handleDisconnect" />
    <clavier v-on:on="handleOn"
             v-on:off="handleOff" />
  </main>
  <SequenceurOn />
</div>
</template>

<script>
import Clavier from '@/components/on-off/Clavier.vue'
import Ecran from '@/components/on-off/Ecran.vue'

import onOff from '@/lib/onOff.js'
import SequenceurOn from './SequenceurOn.vue'

export default {
  name: 'OnOff',
  components: {
    Clavier,
    Ecran,
    SequenceurOn
  },
  data: function () {
    return {
      connectedMod: undefined,
      playingTracks: new Set()
    }
  },
  methods: {
    handleOn: function (trackId) {
      this.playTrack(trackId)
      this.playingTracks.add(trackId)
    },
    playTrack: function (trackId) {
      onOff.play(trackId, this.connectedMod)
    },
    handleOff: function (trackId) {
      this.stopTrack(trackId)
      this.playingTracks.delete(trackId)
    },
    stopTrack: function (trackId) {
      for (const modId of [...Array(4).keys()]) {
        onOff.stop(trackId, modId)
      }
    },
    handleConnect: function (modId) {
      this.connectedMod = modId
      this.reloadTracks()
    },
    handleDisconnect: function () {
      this.connectedMod = undefined
      this.reloadTracks()
    },
    reloadTracks: function () {
      for (const trackId of this.playingTracks) {
        this.stopTrack(trackId)
        this.playTrack(trackId)
      }
    }
  }
}
</script>

<style scoped>
header {
  text-align: center;
  width: 75%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  }
header p{
  font-size: 1.2rem;
}
h1{
  color: #575757;
  letter-spacing: 0.2em;
  font-size: 2rem;
  padding-top: 30px;
}
main {
  display: inline-block;
  width: fit-content;
  height: auto;
}
.instruction{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.instruction p{
  line-height: 2rem;
  text-align: left;
  font-size: 1.2rem;
}
.videoSection{
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
.videoParent{
  position: relative;
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 1080px){
  header {
  text-align: center;
  width: 75%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  }
header p{
  font-size: 1rem;
}
h1{
  color: #575757;
  letter-spacing: 0.2em;
  font-size: 1.5rem;
  padding-top: 30px;
}
.videoSection{
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.videoParent{
  position: relative;
  width: 100%;
  height: auto;
}
}
</style>
