<template>
<div class="ecran">
  <img src="@/assets/on-off/Ecran.png" />

  <div v-for="i in 3" v-bind:key="`connectors-${i}`">
    <connector v-for="pos in ['left', 'right']"
               v-bind:key="`connector-${pos}-${i}`"
               v-bind:id="`connector-${pos}-${i}`"
               v-bind:isConnected="connectedMod === i"
               v-on:connect="handleConnect"
               v-on:disconnect="handleDisconnect"
               v-bind:modId="i" />
    <cable v-bind:cableColor="cableMapping[i - 1]"
           v-bind:isConnected="connectedMod === i"
           v-bind:id="`cable-${cableMapping[i - 1]}`" />
  </div>
</div>
</template>

<script>
import Connector from '@/components/on-off/Connector.vue'
import Cable from '@/components/on-off/Cable.vue'

export default {
  name: 'Ecran',
  components: {
    Connector,
    Cable
  },
  data () {
    return {
      cableMapping: ['red', 'green', 'yellow']
    }
  },
  props: {
    connectedMod: {
      type: Number,
      default: undefined
    }
  },
  methods: {
    handleConnect: function (modId) {
      this.$emit('connect', modId)
    },
    handleDisconnect: function () {
      this.$emit('disconnect')
    }
  }
}
</script>

<style scoped>
/*DESKTOP SIZE*/
@media only screen and (min-width: 1800px){
.ecran {
  position: relative;
  width: 1205px;
  height: 541px;
  left: 215px;
}

.ecran img{
  width: 100%;
  height: 100%;
}

#connector-left-1 {
  position: absolute;
  width: 29px;
  height: 31px;
  bottom: 73px;
  left: 200px;
}

#connector-right-1 {
  position: absolute;
  width: 29px;
  height: 31px;
  bottom: 73px;
  left: 347px;
}

#connector-left-2 {
  position: absolute;
  width: 29px;
  height: 31px;
  top: 185px;
  left: 588px;
}

#connector-right-2 {
  position: absolute;
  width: 29px;
  height: 31px;
  top: 332px;
  left: 588px;
}

#connector-left-3 {
  position: absolute;
  width: 29px;
  height: 31px;
  top: 81px;
  right: 353px;
}

#connector-right-3 {
  position: absolute;
  width: 29px;
  height: 31px;
  top: 81px;
  right: 206px;
}

#cable-red {
  position: absolute;
  width: 175px;
  height: 243px;
  top: 225px;
  left: 200px;
}

#cable-green {
  position: absolute;
  width: 243px;
  height: 175px;
  top: 188px;
  left: 590px;
}

#cable-yellow {
  position: absolute;
  width: 190px;
  height: 230px;
  top: 77px;
  left: 815px;
}
}
/*LAPTOP SIZE*/
@media only screen and (min-width: 1080px) and (max-width: 1800px){
.ecran {
  position: relative;
  width: 770px;
  height: 346px;
  left: 139px;
}

.ecran img{
  width: 100%;
  height: 100%;
}

#connector-left-1 {
  position: absolute;
  width: 18px;
  height: 19px;
  bottom: 47px;
  left: 128px;
}

#connector-right-1 {
  position: absolute;
  width: 18px;
  height: 19px;
  bottom: 47px;
  left: 222px;
}

#connector-left-2 {
  position: absolute;
  width: 18px;
  height: 19px;
  top: 119px;
  left: 376px;
}

#connector-right-2 {
  position: absolute;
  width: 18px;
  height: 19px;
  top: 213px;
  left: 376px;
}

#connector-left-3 {
  position: absolute;
  width: 18px;
  height: 19px;
  top: 53px;
  right: 226px;
}

#connector-right-3 {
  position: absolute;
  width: 18px;
  height: 19px;
  top: 53px;
  right: 132px;
}

#cable-red {
  position: absolute;
  width: 115px;
  height: 160px;
  top: 144px;
  left: 126px;
}

#cable-green {
  position: absolute;
  width: 160px;
  height: 113px;
  top: 120px;
  left: 377px;
}

#cable-yellow {
  position: absolute;
  width: 122px;
  height: 160px;
  top: 52px;
  left: 522px;
}
}
/*TABLET SIZE*/
@media only screen and (min-width: 768px) and (max-width: 1080px){
.ecran {
  position: relative;
  width: 477px;
  height: 215px;
  left: 86px;
}

.ecran img{
  width: 100%;
  height: 100%;
}

#connector-left-1 {
  position: absolute;
  width: 11px;
  height: 12px;
  bottom: 29px;
  left: 79px;
}

#connector-right-1 {
  position: absolute;
  width: 11px;
  height: 12px;
  bottom: 29px;
  left: 138px;
}

#connector-left-2 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 74px;
  left: 233px;
}

#connector-right-2 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 132px;
  left: 233px;
}

#connector-left-3 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 33px;
  right: 140px;
}

#connector-right-3 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 33px;
  right: 82px;
}

#cable-red {
  position: absolute;
  width: 71px;
  height: 99px;
  top: 89px;
  left: 78px;
}

#cable-green {
  position: absolute;
  width: 99px;
  height: 70px;
  top: 74px;
  left: 233px;
}

#cable-yellow {
  position: absolute;
  width: 76px;
  height: 99px;
  top: 32px;
  left: 324px;
}
}
/* MOBILE */
@media only screen and (min-width: 300px) and (max-width: 768px){
.ecran {
  position: relative;
  width: 286px;
  height: 129px;
  left: 52px;
}

.ecran img{
  width: 100%;
  height: 100%;
}

#connector-left-1 {
  position: absolute;
  width: 7px;
  height: 7px;
  bottom: 17px;
  left: 47px;
}

#connector-right-1 {
  position: absolute;
  width: 7px;
  height: 7px;
  bottom: 17px;
  left: 83px;
}

#connector-left-2 {
  position: absolute;
  width: 7px;
  height: 7px;
  top: 44px;
  left: 140px;
}

#connector-right-2 {
  position: absolute;
  width: 7px;
  height: 7px;
  top: 79px;
  left: 140px;
}

#connector-left-3 {
  position: absolute;
  width: 7px;
  height: 7px;
  top: 20px;
  right: 84px;
}

#connector-right-3 {
  position: absolute;
  width: 7px;
  height: 7px;
  top: 20px;
  right: 49px;
}

#cable-red {
  position: absolute;
  width: 43px;
  height: 59px;
  top: 53px;
  left: 47px;
}

#cable-green {
  position: absolute;
  width: 59px;
  height: 42px;
  top: 44px;
  left: 140px;
}

#cable-yellow {
  position: absolute;
  width: 46px;
  height: 59px;
  top: 19px;
  left: 194px;
}
}
</style>
