<template>
<div class="clavier">
  <img src="@/assets/on-off/Clavier.png" />

  <push-button v-for="i in [2, 4, 6]"
               class="push-button"
               v-bind:trackId="i"
               v-bind:key="`push-button-${i}`"
               v-bind:id="`push-button-${i}`"
               v-on:push="handlePush" />

  <switch-button v-for="i in [1, 3, 5]"
                 class="switch-button"
                 v-bind:trackId="i"
                 v-bind:key="`switch-button-${i}`"
                 v-bind:id="`switch-button-${i}`"
                 v-on:switch="handleSwitch" />
</div>
</template>

<script>
import PushButton from '@/components/on-off/PushButton.vue'
import SwitchButton from '@/components/on-off/SwitchButton.vue'

export default {
  name: 'Clavier',
  components: {
    PushButton,
    SwitchButton
  },
  methods: {
    handlePush: function (isActive, trackId) {
      this.$emit(isActive ? 'on' : 'off', trackId)
    },
    handleSwitch: function (isActive, trackId) {
      this.$emit(isActive ? 'on' : 'off', trackId)
    }
  }
}
</script>

<style scoped>
/* DESKTOP SIZE */
@media only screen and (min-width: 1800px){
header, main {
    position: relative;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}
.clavier {
  display: inline-block;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 383px;
  width: 1638px;
}

.clavier img {
  width: 100%;
  height: 100%;
}

.push-button {
  position: absolute;
  top: 115px;
  width: 80px;
  height: 157px;
}

#push-button-2 {
  left: 480px;
}

#push-button-4 {
  left: 870px;
}

#push-button-6 {
  right: 300px;
}

.switch-button {
  top: 167px;
  position: absolute;
  width: 31px;
  height: 65px;
}

#switch-button-1 {
  left: 295px;
}

#switch-button-3 {
  left: 700px;
}

#switch-button-5 {
  right: 515px;
}
}
/* LAPTOP SIZE */
@media only screen and (min-width: 1080px) and (max-width: 1800px){
header, main {
    position: relative;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}
.clavier {
  display: inline-block;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 246px;
  width: 1048px;
}

.clavier img {
  width: 100%;
  height: 100%;
}

.push-button {
  position: absolute;
  top: 80px;
  width: 42px;
  height: 94px;
}

#push-button-2 {
  left: 306px;
}

#push-button-4 {
  left: 558px;
}

#push-button-6 {
  right: 190px;
}

.switch-button {
  top: 109px;
  position: absolute;
  width: 18px;
  height: 37px;
}

#switch-button-1 {
  left: 192px;
}

#switch-button-3 {
  left: 446px;
}

#switch-button-5 {
  right: 332px;
}
}
/* TABLET SIZE */
@media only screen and (min-width: 765px) and (max-width: 1080px){
header, main {
    position: relative;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}
.clavier {
  display: inline-block;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 153px;
  width: 650px;
}

.clavier img {
  width: 100%;
  height: 100%;
}

.push-button {
  position: absolute;
  top: 50px;
  width: 26px;
  height: 58px;
}

#push-button-2 {
  left: 190px;
}

#push-button-4 {
  left: 346px;
}

#push-button-6 {
  right: 118px;
}

.switch-button {
  top: 68px;
  position: absolute;
  width: 11px;
  height: 23px;
}

#switch-button-1 {
  left: 119px;
}

#switch-button-3 {
  left: 277px;
}

#switch-button-5 {
  right: 206px;
}
}
/* MOBILE */
@media only screen and (min-width: 300px) and (max-width: 768px){
header, main {
    position: relative;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}
.clavier {
  display: inline-block;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 92px;
  width: 390px;
}

.clavier img {
  width: 100%;
  height: 100%;
}

.push-button {
  position: absolute;
  top: 30px;
  width: 16px;
  height: 35px;
}

#push-button-2 {
  left: 114px;
}

#push-button-4 {
  left: 208px;
}

#push-button-6 {
  right: 71px;
}

.switch-button {
  top: 41px;
  position: absolute;
  width: 7px;
  height: 14px;
}

#switch-button-1 {
  left: 71px;
}

#switch-button-3 {
  left: 166px;
}

#switch-button-5 {
  right: 124px;
}
}
</style>
